import { all } from "redux-saga/effects";
import { sagas as rewards } from "../modules/rewards";
import { sagas as wallet } from "../modules/travel-wallet";
import { sagas as passengers } from "../modules/passengers";

export function* rootSaga() {
  yield all([
    rewards.watchFetchRewardsAccounts(),
    wallet.watchFetchTravelWalletDetails(),
    wallet.watchFetchTravelWalletCreditHistory(),
    passengers.watchFetchUserPassengers(),
  ]);
}
