import { combineReducers } from "redux";

import { reducer as rewards } from "../modules/rewards/reducer";
import { reducer as wallet } from "../modules/travel-wallet/reducer";
import passengersReducer from "../modules/passengers/reducer";

export const rootReducer = combineReducers({ 
    passengers: passengersReducer,
    rewards, wallet
 });
