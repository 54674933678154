import React, { useState, useEffect } from "react";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { ExperimentsHookProvider } from "@capone/experiments";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { CorpSessionInfo, TravelWalletClientAssetProps, TravelWalletModuleProps } from "redmond";
import ExperimentsProvider from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import fetchUserInfo from "./api/v1/user/fetchUserInfo";
import AxiosInterceptors from "./components/Counter/AxiosInterceptors";
import { AgentBanner, useDeviceTypes } from "halifax";
import { RewardsBanner } from "./modules/rewards/components";
import { store } from "./store";
import { TravelWalletLanding } from "./pages/TravelWalletLanding";
import { PATH_HOME, PATH_TRAVEL_SALE } from "./utils/paths";
import { TravelSaleLanding } from "./pages/TravelSaleLanding";
import { isCorpTenant } from "@capone/common";
import { config } from "./api/config";
import { TravelerModalWrapper } from "./pages/TravelerModalWrapper";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptTravelWalletModule",
  seed: "ptTravelWalletModule",
});

export const ClientContext = React.createContext<
  Partial<TravelWalletClientAssetProps>
>({});

const App = (props: TravelWalletModuleProps) => {
  const { experiments, baseHistory, isAgentPortal, clientAssets } = props;
  const { matchesMobile } = useDeviceTypes();
  const [activeTheme, setActiveTheme] = useState(props.theme);
  const [locale, setLocale] = useState(props.language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);
  const userRoles = isCorpTenant(config.TENANT)
    ? (sessionInfo as CorpSessionInfo).corporateInfo.role
    : [];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider value={{ ...clientAssets, sessionInfo }}>
                <div className="App">
                  <StylesProvider generateClassName={generateClassName}>
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal ? (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            ) : props.baseHistory.location.pathname !==
                              PATH_TRAVEL_SALE ? (
                              <Route path="*">
                                <RewardsBanner />
                              </Route>
                            ) : null}
                          </Switch>
                          <Route path={PATH_TRAVEL_SALE}>
                            <TravelSaleLanding />
                          </Route>
                          <Route path={PATH_HOME} exact>
                            <TravelWalletLanding />
                          </Route>
                        </IntlProvider>
                      ) : (
                        <div>Loading</div>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
                {isCorpTenant(config.TENANT) && (
                  <TravelerModalWrapper
                    isMobile={matchesMobile}
                    userRoles={userRoles}
                  />
                )}
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export default App;
